import { Translation } from './translation';
import { Translations } from '../constants/translations';
import { Block } from './block';
import { Blocks } from '../constants/blocks';

export const About = () => {
  return (
    <section id="about" className="textSection">
      <div className="container container_narrow">
        <div className="textSection__header">
          <h2 className="h1">
            <Translation k={Translations.about} />
          </h2>
        </div>

        <div className="contentCard">
          <div className="text">
            <Block k={Blocks.about_us} />
          </div>
        </div>
      </div>
    </section>
  );
};
