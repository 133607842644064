export const Loader = () => {
  return (
    <span className={`loader__container`}>
      <span className={`loader`}>
        <span />
        <span />
        <span />
        <span />
      </span>
    </span>
  );
};
