import { createReducer } from '@reduxjs/toolkit';
import { getStats, setSelected, setShowAll } from './actions';

export type CountryStats = {
  id: number;
  date: string;
  location: string;
  population: number;
  total_cases: number;
  total_deaths: number;
  people_fully_vaccinated: number | null;
  people_fully_vaccinated_per_hundred: number | null;
};

const initialState: {
  stats: CountryStats[];
  selected: null | number;
  showAll: boolean;
} = {
  stats: [],
  selected: null,
  showAll: false,
};

export const statsReducer = createReducer(initialState, builder => {
  builder.addCase(getStats.fulfilled, (state, { payload }) => {
    state.stats = payload;
  });
  builder.addCase(setSelected, (state, { payload }) => {
    state.selected = payload;
  });
  builder.addCase(setShowAll, (state, { payload }) => {
    state.showAll = payload;
  });
});
