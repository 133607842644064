import { Header } from './components/header';
import { SectionHeader } from './components/sectionHeader';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStats } from './store/stats/actions';
import { CountryCard } from './components/countryCard';
import { VaccinatedCard } from './components/vacinatedCard';
import { InfoCard } from './components/infoCard';
import { getBlocks, getTranslations } from './store/texts/actions';
import { Translation } from './components/translation';
import { Translations } from './constants/translations';
import { Block } from './components/block';
import { Blocks } from './constants/blocks';
import { DateCard } from './components/dateCard';
import { PrivacyModal } from './components/privacyModal';
import { TermsModal } from './components/termsModal';
import { LearnMoreCard } from './components/learnMoreCard';
import { RecommendedInfoCard } from './components/recommendedInfoCard';
import { About } from './components/about';
import { Contacts } from './components/contacts';
import { Footer } from './components/footer';
import { useLoading } from './store/common/selectors';
import { LoadingScreen } from './components/loadingScreen';
import { CookiePopup } from './components/cookiePopup';
import { ScrollButton } from './components/scrollButton';

const App = () => {
  const dispatch = useDispatch();
  const loading = useLoading();

  useEffect(() => {
    dispatch(getTranslations());
    dispatch(getBlocks());
    dispatch(getStats());
  }, [dispatch]);

  console.log(JSON.stringify(Object.keys(Blocks)));

  return (
    <div className="App">
      <CookiePopup />
      {loading ? <LoadingScreen /> : null}
      <Header />
      <SectionHeader
        title={<Translation k={Translations.main_title} />}
        text={<Translation k={Translations.main_subtitle} />}
      />
      <main>
        <div className="container">
          <div className="contentGrid">
            <CountryCard />
            <VaccinatedCard />
            <InfoCard />
            <LearnMoreCard />
            <RecommendedInfoCard />
            <DateCard />
          </div>
          <div className="contentGrid__disclaimer text">
            <Block k={Blocks.grid_disclaimer} />
          </div>
        </div>
        <section id="ami-bami">
          <div className="sectionHeader sectionHeader_content">
            <div className="container">
              <div className="sectionHeader__content">
                <h2 className="h1 sectionHeader__title">
                  <Translation k={Translations.amibami_title} parse />
                </h2>
                <p className="sectionHeader__text">
                  <Translation k={Translations.amibami_subtitle} />
                </p>
                <div className="sectionHeader__infoCardContainer">
                  <div className="sectionHeader__infoCard">
                    <Translation k={Translations.amibami_card_text} parse />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="globeCardContainer">
            <div className="globeCardContainer__row">
              <div className="globeCard">
                <div className="h1 globeCard__title">
                  <Translation k={Translations.ami} />
                </div>
                <div className="globeCard__globe">
                  <canvas className="spinningGlobe" />
                </div>
              </div>
              <div className="globeCard">
                <div className="h1 globeCard__title">
                  <Translation k={Translations.bami} />
                </div>
                <div className="globeCard__globe">
                  <canvas className="spinningGlobe" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <About />
        <Contacts />
      </main>
      <Footer />
      <PrivacyModal />
      <TermsModal />
      <ScrollButton />
    </div>
  );
};

export default App;
