import { useEffect, useState } from 'react';
import { Translation } from './translation';
import { Translations } from '../constants/translations';
import { Block } from './block';
import { Blocks } from '../constants/blocks';

export const CookiePopup = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cookies = document.cookie;
    let shouldOpen = true;
    if (cookies) {
      const cookieArray = cookies.split('; ');
      const cookie = cookieArray.find(row => row.startsWith('accepted='));
      if (cookie) {
        shouldOpen = false;
      }
    }
    setOpen(shouldOpen);
  }, []);

  const handleClose = () => {
    setOpen(false);
    document.cookie = `accepted=1;max-age=31536000`;
  };

  return (
    <aside className={`cookiePopup ${!open ? `cookiePopup_closed` : ''}`}>
      <div className="container cookiePopup__container">
        <button className="button button_iconOnly button_mainBg cookiePopup__close">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M18 18L6 6"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
        <div className="cookiePopup__title">
          <Translation k={Translations.cookies_first} />
        </div>
        <div className="cookiePopup__text text">
          <Block k={Blocks.cookies} />
        </div>
        <div className="cookiePopup__action">
          <button className="button button_important" onClick={handleClose}>
            <span className="button__label">
              <Translation k={Translations.ok_continue} />
            </span>
          </button>
        </div>
      </div>
    </aside>
  );
};
