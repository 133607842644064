import { Block } from './block';
import { Blocks } from '../constants/blocks';
import { ContentCard } from './contentCard';
import { useTranslation } from '../hooks/useTranslation';
import { Translations } from '../constants/translations';

export const RecommendedInfoCard = () => {
  const whoLink = useTranslation(Translations.who_link);
  const cdcLink = useTranslation(Translations.cdc_link);
  const ecdcLink = useTranslation(Translations.ecdc_link);
  const csseAtJhuLink = useTranslation(Translations.csse_at_jhu_link);

  return (
    <ContentCard
      noHeader
      className={`contentCard_content`}
      label={<Block k={Blocks.recommended_information} />}
    >
      <div className="actionGroup">
        <a href={whoLink} className="button">
          <span className="button__label">WHO</span>
          <span className="button__icon">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.92 11.62a1.001 1.001 0 00-.21-.33l-5-5a1.003 1.003 0 10-1.42 1.42l3.3 3.29H7a1 1 0 000 2h7.59l-3.3 3.29a1.001 1.001 0 00.325 1.639 1 1 0 001.095-.22l5-5a1 1 0 00.21-.33 1 1 0 000-.76z"
                fill="#000"
              />
            </svg>
          </span>
        </a>
        <a href={cdcLink} className="button">
          <span className="button__label">CDC</span>
          <span className="button__icon">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.92 11.62a1.001 1.001 0 00-.21-.33l-5-5a1.003 1.003 0 10-1.42 1.42l3.3 3.29H7a1 1 0 000 2h7.59l-3.3 3.29a1.001 1.001 0 00.325 1.639 1 1 0 001.095-.22l5-5a1 1 0 00.21-.33 1 1 0 000-.76z"
                fill="#000"
              />
            </svg>
          </span>
        </a>
        <a href={ecdcLink} className="button">
          <span className="button__label">ECDC</span>
          <span className="button__icon">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.92 11.62a1.001 1.001 0 00-.21-.33l-5-5a1.003 1.003 0 10-1.42 1.42l3.3 3.29H7a1 1 0 000 2h7.59l-3.3 3.29a1.001 1.001 0 00.325 1.639 1 1 0 001.095-.22l5-5a1 1 0 00.21-.33 1 1 0 000-.76z"
                fill="#000"
              />
            </svg>
          </span>
        </a>
        <a href={csseAtJhuLink} className="button">
          <span className="button__label">CSSE at JHU</span>
          <span className="button__icon">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.92 11.62a1.001 1.001 0 00-.21-.33l-5-5a1.003 1.003 0 10-1.42 1.42l3.3 3.29H7a1 1 0 000 2h7.59l-3.3 3.29a1.001 1.001 0 00.325 1.639 1 1 0 001.095-.22l5-5a1 1 0 00.21-.33 1 1 0 000-.76z"
                fill="#000"
              />
            </svg>
          </span>
        </a>
      </div>
    </ContentCard>
  );
};
