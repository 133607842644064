import { Translations } from '../../constants/translations';
import { useAppSelector } from '../index';
import { Blocks } from '../../constants/blocks';

export const useTranslationByKey = (key: Translations) => {
  return useAppSelector(({ texts: { translations } }) => translations[key]);
};

export const useBlockByKey = (key: Blocks) => {
  return useAppSelector(({ texts: { blocks } }) => blocks[key]);
};
