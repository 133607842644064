import { FunctionComponent, ReactNode } from 'react';

const Wrapper: FunctionComponent<{
    header: boolean;
    className: string;
}> = ({ header, children, className }) => {
    if (!header) return <div className={className}>{children}</div>;
    return <header className={className}>{children}</header>;
};

export const SectionHeader: FunctionComponent<{
    title: string | ReactNode;
    text: string | ReactNode;
    header?: boolean;
}> = ({ title, text, header = false }) => {
    return (
        <Wrapper header={header} className="sectionHeader">
            <div className="container">
                <div className="sectionHeader__content">
                    <h1 className="h1 sectionHeader__title">{title}</h1>
                    <p className="sectionHeader__text">{text}</p>
                </div>
            </div>
        </Wrapper>
    );
};
