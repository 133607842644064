import { useAppSelector } from '../index';

export const useStats = () => {
  return useAppSelector(({ stats: { stats } }) => stats);
};

export const useSelected = () => {
  return useAppSelector(({ stats: { stats, selected } }) =>
    stats.find(country => country.id === selected)
  );
};

export const useStataShowAll = () => {
  return useAppSelector(({ stats: { showAll } }) => showAll);
};
