import { Translation } from './translation';
import { Translations } from '../constants/translations';
import { setModals } from '../store/modals/actions';
import { Block } from './block';
import { Blocks } from '../constants/blocks';
import { useDispatch } from 'react-redux';
import { Modals } from '../constants/modals';

export const Footer = () => {
  const dispatch = useDispatch();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__row">
          <div className="footer__content">
            <img
              className="footer__logo"
              src="data:image/svg+xml,%3Csvg width='48' height='74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='13.497' width='20.6' height='20.6' rx='10.3' fill='%23514842'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10.957c0-.002 0-.003 3.197.054 3.196.057 3.196.055 3.196.054v-.007-.003.015l.001.109c.002.106.007.277.02.503.026.454.084 1.125.213 1.938.259 1.643.79 3.783 1.86 5.886C9.55 21.59 11.119 23.6 13.462 25.1c2.332 1.492 5.623 2.603 10.336 2.603s8.033-1.11 10.406-2.612c2.385-1.51 3.999-3.531 5.102-5.626 1.112-2.11 1.684-4.257 1.972-5.9.143-.815.213-1.486.247-1.94a12.64 12.64 0 00.032-.612V11v.007c0 .001 0 .003 3.197.003s3.197.001 3.197.003v.038l-.002.07a19.071 19.071 0 01-.049.983 26.39 26.39 0 01-.325 2.566c-.366 2.086-1.105 4.913-2.612 7.775-1.516 2.877-3.831 5.828-7.34 8.048-2.843 1.799-6.349 3.042-10.628 3.454v16.637c4.28.412 7.785 1.655 10.628 3.454 3.509 2.22 5.824 5.17 7.34 8.048 1.507 2.862 2.246 5.689 2.612 7.775a26.39 26.39 0 01.326 2.566 19.071 19.071 0 01.05 1.053V73.52c0 .002 0 .003-3.197.003s-3.197.002-3.197.003v.003-.009a12.64 12.64 0 00-.032-.612 20.007 20.007 0 00-.247-1.94c-.288-1.643-.86-3.79-1.972-5.9-1.103-2.094-2.717-4.117-5.102-5.626-2.373-1.501-5.693-2.612-10.406-2.612-4.713 0-8.004 1.111-10.336 2.603-2.343 1.5-3.912 3.51-4.973 5.595-1.07 2.103-1.602 4.243-1.861 5.885a19.89 19.89 0 00-.213 1.939 12.726 12.726 0 00-.02.612v.012-.007c0-.001 0-.003-3.197.054-3.196.057-3.196.056-3.196.054v-.038a8.166 8.166 0 01.001-.294c.003-.186.011-.442.03-.76a26.27 26.27 0 01.28-2.568c.329-2.087 1.017-4.919 2.478-7.789 1.47-2.887 3.74-5.85 7.225-8.08 2.821-1.805 6.313-3.05 10.586-3.462V33.948c-4.273-.413-7.765-1.657-10.586-3.463-3.484-2.23-5.756-5.192-7.225-8.08-1.46-2.87-2.15-5.701-2.479-7.788a26.27 26.27 0 01-.28-2.569 19.075 19.075 0 01-.03-1.053V10.957z' fill='%23514842'/%3E%3C/svg%3E"
              alt=""
            />
            <nav className="footer__nav">
              <a href="#about">
                <Translation k={Translations.about} />
              </a>
              <a href="#contacts">
                <Translation k={Translations.contacts} />
              </a>
              <button onClick={() => dispatch(setModals(Modals.terms, true))}>
                <Translation k={Translations.terms_and_conditions} />
              </button>
              <button onClick={() => dispatch(setModals(Modals.privacy, true))}>
                <Translation k={Translations.privacy} />
              </button>
            </nav>
          </div>
          <div className={`footer__text text`}>
            <Block k={Blocks.disclaimer} />
          </div>
        </div>
        <div className="footer__copyright">
          <Translation k={Translations.copyright} />
        </div>
      </div>
    </footer>
  );
};
