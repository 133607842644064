// export const setTranslations = translations => {
//     return {
//         type: 'SET_TRANSLATIONS',
//         return: translations,
//     };
// };

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getTranslations = createAsyncThunk(
  '@texts/GET_TRANSLATIONS',
  async () => {
    const res = await fetch('/app/translations');
    return await res.json();
  }
);

export const getBlocks = createAsyncThunk(
  '@texts/GET_BLOCKS',
  async () => {
    const res = await fetch('/app/blocks');
    return await res.json();
  }
);
