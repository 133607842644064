import { createReducer } from '@reduxjs/toolkit';
import { Languages } from '../../constants/languages';
import { getBlocks, getTranslations } from './actions';

export type Translation = {
  [lang in Languages]: string;
};

export type Block = {
  [lang in Languages]: string;
};

const initialState: {
  translations: { [key: string]: Translation };
  blocks: { [key: string]: Block };
} = {
  translations: {},
  blocks: {},
};

export const textsReducer = createReducer(initialState, builder => {
  builder.addCase(getTranslations.fulfilled, (state, action) => {
    state.translations = action.payload.dictionary;
  });
  builder.addCase(getBlocks.fulfilled, (state, action) => {
    state.blocks = action.payload;
  });
});
