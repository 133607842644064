import { ChangeEvent, FunctionComponent, ReactNode } from 'react';

export const Toggle: FunctionComponent<{
  firstLabel: string | ReactNode;
  secondLabel: string | ReactNode;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id: string;
}> = ({ firstLabel, secondLabel, checked, onChange, id }) => {
  return (
    <div className={`toggle`}>
      <input
        type="checkbox"
        id={id}
        className={`toggle__input`}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className={`toggle__row`}>
        <span className={`toggle__label`}>{firstLabel}</span>
        <span className={`toggle__toggle`} />
        <span className={`toggle__label`}>{secondLabel}</span>
      </label>
    </div>
  );
};
