import { useEffect, useState } from 'react';
import { Translation } from './translation';
import { Translations } from '../constants/translations';

export const ScrollButton = () => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const watchScroll = (event: Event) => {
      if (window.scrollY > 100) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    };
    document.addEventListener('scroll', watchScroll, { passive: true });
    return () => {
      document.removeEventListener('scroll', watchScroll);
    };
  }, []);

  const scrollUp = () => {
    window.scroll({
      top: window.innerHeight - 100,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className={`button scrollButton ${hidden ? `scrollButton_hidden` : ''}`}
      onClick={scrollUp}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="currentColor"
        className={`scrollButton__icon`}
      >
        <path d="M11 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L13 16.17V5c0-.55-.45-1-1-1s-1 .45-1 1z" />
      </svg>
      <span className={`scrollButton__label`}>
        <Translation k={Translations.scroll_down} />
      </span>
    </button>
  );
};
