import { createReducer } from '@reduxjs/toolkit';
import { Languages } from '../../constants/languages';
import { Loading } from '../../constants/loading';
import { getBlocks, getTranslations } from '../texts/actions';

const initialState: {
  lang: Languages;
  loading: Loading[];
} = { lang: Languages.en, loading: [Loading.translations, Loading.blocks] };

export const commonReducer = createReducer(initialState, builder => {
  builder.addCase(getTranslations.fulfilled, state => {
    state.loading = state.loading.filter(item => item !== Loading.translations);
  });
  builder.addCase(getTranslations.rejected, state => {
    state.loading = state.loading.filter(item => item !== Loading.translations);
  });
  builder.addCase(getBlocks.fulfilled, state => {
    state.loading = state.loading.filter(item => item !== Loading.blocks);
  });
  builder.addCase(getBlocks.rejected, state => {
    state.loading = state.loading.filter(item => item !== Loading.blocks);
  });
});
