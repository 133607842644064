import { useTranslationByKey } from '../store/texts/selectors';
import { useCurrentLang } from '../store/common/selectors';
import { Translations } from '../constants/translations';

export const useTranslation = (key: Translations) => {
  const translation = useTranslationByKey(key);
  const currentLang = useCurrentLang();

  if (translation && translation[currentLang]) {
    return translation[currentLang];
  }
  return `${key}`;
};
