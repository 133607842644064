import {
  useSelected,
  useStataShowAll,
  useStats,
} from '../store/stats/selectors';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelected, setShowAll } from '../store/stats/actions';
import { Translation } from './translation';
import { Translations } from '../constants/translations';
import { Toggle } from './toggle';

export const CountryCard = () => {
  const dispatch = useDispatch();
  const stats = useStats();
  const selected = useSelected();
  const showAll = useStataShowAll();

  const [open, setOpen] = useState(false);

  const [sort, setSort] = useState('az');

  const getSortedStats = () => {
    const sortedStats = [...stats];
    if (sort === 'za') {
      return sortedStats.reverse();
    }
    if (!showAll) {
      return sortedStats.filter(
        country => country.people_fully_vaccinated_per_hundred
      );
    }
    return sortedStats;
  };

  return (
    <div className="contentCard countryCard">
      <div className="countryCard__body">
        <div className="contentCard__header contentCard__header_mobileNoContent">
          <h2 className="contentCard__title">
            <Translation k={Translations.country} />
          </h2>
          <div className="contentCard__action contentCard__action_desktop">
            <div className="dropdown">
              <button className="button">
                <span className="button__label">
                  <Translation k={Translations.sort_by} />
                </span>
                <span className="button__icon">
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 6l4 4 4-4"
                      stroke="#000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
              <div className="dropdown__wrapper">
                <ul className="dropdown__list">
                  <li>
                    <button
                      className="dropdown__button"
                      onClick={() => setSort('az')}
                    >
                      <Translation k={Translations.a_z} />
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown__button"
                      onClick={() => setSort('za')}
                    >
                      <Translation k={Translations.z_a} />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="contentCard__action contentCard__action_mobile">
            <button className="button" onClick={() => setOpen(true)}>
              <span className="button__label">
                <Translation k={Translations.choose} />
              </span>
              <span className="button__icon">
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.92 11.62a1.001 1.001 0 00-.21-.33l-5-5a1.003 1.003 0 10-1.42 1.42l3.3 3.29H7a1 1 0 000 2h7.59l-3.3 3.29a1.001 1.001 0 00.325 1.639 1 1 0 001.095-.22l5-5a1 1 0 00.21-.33 1 1 0 000-.76z"
                    fill="#000"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={`countryCard__filter`}>
          <Toggle
            firstLabel={<Translation k={Translations.with_data} />}
            secondLabel={<Translation k={Translations.all} />}
            checked={showAll}
            onChange={event => {
              dispatch(setShowAll(event.target.checked));
            }}
            id={`filter_with_data`}
          />
        </div>
        <div className="countryCard__content">
          <div className={`countryList ${open ? `countryList_open` : ''}`}>
            <div className="countryList__container">
              <div className="countryList__header">
                <button
                  className="button button_iconOnly"
                  onClick={() => setOpen(false)}
                >
                  <span className="button__icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 18L18 6"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M18 18L6 6"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </button>
                <div className="dropdown">
                  <button className="button">
                    <span className="button__label">
                      <Translation k={Translations.sort_by} />
                    </span>
                    <span className="button__icon">
                      <svg
                        width="16"
                        height="16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 6l4 4 4-4"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </button>
                  <div className="dropdown__wrapper">
                    <ul className="dropdown__list">
                      <li>
                        <button
                          className="dropdown__button"
                          onClick={() => setSort('az')}
                        >
                          <Translation k={Translations.a_z} />
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown__button"
                          onClick={() => setSort('za')}
                        >
                          <Translation k={Translations.z_a} />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={`countryCard__filter countryCard__filter_mobile`}>
                <Toggle
                  firstLabel={<Translation k={Translations.with_data} />}
                  secondLabel={<Translation k={Translations.all} />}
                  checked={showAll}
                  onChange={event => {
                    dispatch(setShowAll(event.target.checked));
                  }}
                  id={`filter_with_data`}
                />
              </div>
              <ul className="countryCard__list">
                {getSortedStats().map(country => {
                  return (
                    <li key={country.id}>
                      <button
                        className={`countryCard__button ${
                          selected && country.id === selected.id
                            ? 'countryCard__button_active'
                            : ''
                        }`}
                        onClick={() => {
                          dispatch(setSelected(country.id));
                          setOpen(false);
                        }}
                      >
                        {country.location}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div
            className="countryList__curtain"
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};
