// export const setModals = (key, value) => {
//     return {
//         type: 'SET_MODALS',
//         payload: { key, value },
//     };
// };

import { createAction } from '@reduxjs/toolkit';
import { Modals } from '../../constants/modals';

export const setModals = createAction(
  '@modals/SET_MODALS',
  (key: Modals, value: boolean) => {
    return { payload: { key, value } };
  }
);
