import { FunctionComponent } from 'react';
import logo from '../img/logo.svg';
import { Translation } from './translation';
import { Translations } from '../constants/translations';

export const Header: FunctionComponent = () => {
  return (
    <div className="header">
      <div className="container header__row">
        <a href="/">
          <img alt="amibami" src={logo} />
        </a>
        <div className="dropdown">
          <button className="button button_iconOnly nav__button">
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 8h18c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1zm10 8H3c-.6 0-1 .4-1 1s.4 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1zm8-5H3c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.4 1-1s-.4-1-1-1z"
                fill="#000"
              />
            </svg>
          </button>

          <div className="dropdown__wrapper">
            <ul className="dropdown__list">
              <li>
                <a href="#ami-bami" className="dropdown__button">
                  <Translation k={Translations.ami_and_bami} />
                </a>
              </li>
              <li>
                <a href="#about" className="dropdown__button">
                  <Translation k={Translations.about} />
                </a>
              </li>
              <li>
                <a href="#contacts" className="dropdown__button">
                  <Translation k={Translations.contacts} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
