import { Modals } from '../../constants/modals';
import { createReducer } from '@reduxjs/toolkit';
import { setModals } from './actions';

type ModalsType = {
  [key in Modals]: boolean;
};

const initialState: ModalsType = {
  [Modals.privacy]: false,
  [Modals.terms]: false,
};

export const modalsReducer = createReducer(initialState, builder => {
  builder.addCase(setModals, (state, { payload }) => {
    const { key, value } = payload;
    state[key] = value;
  });
});
