import { useBlockByKey } from '../store/texts/selectors';
import { useCurrentLang } from '../store/common/selectors';
import { Blocks } from '../constants/blocks';

export const useBlock = (key: Blocks) => {
  const block = useBlockByKey(key);
  const currentLang = useCurrentLang();

  if (block && block[currentLang]) {
    return block[currentLang];
  }
  return `blocks.${key}`;
};
