import { useTranslation } from '../hooks/useTranslation';
import { Translations } from '../constants/translations';
import parser from 'html-react-parser';

export const Translation = ({
  k,
  parse = false,
}: {
  k: Translations;
  parse?: boolean;
}) => {
  const translation = useTranslation(k);
  if (parse) return <>{parser(translation)}</>;
  return <>{translation}</>;
};
