import { ContentCard } from './contentCard';
import { useSelected } from '../store/stats/selectors';
import { Translation } from './translation';
import { Translations } from '../constants/translations';

export const DateCard = () => {
  const selected = useSelected();

  if (!selected) {
    return (
      <ContentCard className="contentCard_content" noHeader>
        <p>
          <Translation k={Translations.select_country} />
        </p>
      </ContentCard>
    );
  }
  const { date } = selected;
  return (
    <ContentCard noHeader className={`contentCard_content`}>
      <div className="lastUpdated">
        <div>
          <Translation k={Translations.latest_update} />: <b>{date}</b>
        </div>
      </div>
    </ContentCard>
  );
};
