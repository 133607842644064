import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { commonReducer } from './common';
import { statsReducer } from './stats';
import { modalsReducer } from './modals';
import { textsReducer } from './texts';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    stats: statsReducer,
    modals: modalsReducer,
    texts: textsReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
