import smile from '../img/smile.svg';
import frown from '../img/frown.svg';
import { FunctionComponent } from 'react';

export const GraphGrid: FunctionComponent<{ percentage: number }> = ({
  percentage,
}) => {
  const getGraph = () => {
    const fraction = Math.round(percentage);
    const list = [];
    for (let i = 1; i <= 100; i++) {
      list.push(<img key={i} src={i <= fraction ? smile : frown} alt="" />);
    }
    return list;
  };

  return <div className={`graphGrid`}>{getGraph()}</div>;
};
