import { FunctionComponent, ReactNode } from 'react';

export const ContentCard: FunctionComponent<{
    title?: string | ReactNode;
    secondaryTitle?: string | ReactNode;
    action?: ReactNode;
    actionDesktop?: ReactNode;
    actionMobile?: ReactNode;
    mobileNoContent?: boolean;
    className: string;
    label?: string | ReactNode;
    noHeader?: boolean;
}> = ({
    title,
    secondaryTitle,
    action,
    actionDesktop,
    actionMobile,
    children,
    mobileNoContent,
    className = '',
    label,
    noHeader,
}) => {
    return (
        <div className={`contentCard ${className}`}>
            {!noHeader ? (
                <div
                    className={`contentCard__header ${
                        mobileNoContent
                            ? 'contentCard__header_mobileNoContent'
                            : ''
                    }`}
                >
                    <h2 className="contentCard__title">{title}</h2>
                    {secondaryTitle ? (
                        <div className="contentCard__title">
                            {secondaryTitle}
                        </div>
                    ) : null}
                    {action ? (
                        <div className="contentCard__action">{action}</div>
                    ) : null}
                    {actionDesktop ? (
                        <div className="contentCard__action">
                            {actionDesktop}
                        </div>
                    ) : null}
                    {actionMobile ? (
                        <div className="contentCard__action">
                            {actionMobile}
                        </div>
                    ) : null}
                </div>
            ) : null}
            {label ? (
                <div className="contentCard__label text">{label}</div>
            ) : (
                ''
            )}
            {children}
        </div>
    );
};
