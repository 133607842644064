import { FunctionComponent, ReactNode } from 'react';

export const Modal: FunctionComponent<{
  title: string | ReactNode;
  active: boolean;
  onClose: () => void;
}> = ({ title, children, active, onClose }) => {
  return (
    <div className={`modal ${active ? `modal_open` : ''}`} id="terms">
      <div className="modal__wrapper">
        <div className="modal__container">
          <div className="modal__curtain" onClick={onClose} />
          <div className="modal__body">
            <button
              className="button button_iconOnly modal__close"
              onClick={onClose}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M18 18L6 6"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <h2 className="h2 modal__title">{title}</h2>
            <div className="text">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
