export enum Blocks {
  learn_more = 'learn_more',
  recommended_information = 'recommended_information',
  grid_disclaimer = 'grid_disclaimer',
  about_us = 'about_us',
  contacts = 'contacts',
  disclaimer = 'disclaimer',
  privacy = 'privacy',
  terms_and_conditions = 'terms_and_conditions',
  cookies = 'cookies',
}
