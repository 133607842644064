import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const getStats = createAsyncThunk('@stats/GET_STATS', async () => {
  const res = await fetch('/app/covid/data');
  return await res.json();
});

export const setSelected = createAction('@stats/SET_SELECTED', (id: number) => {
  return {
    payload: id,
  };
});

export const setShowAll = createAction(
  '@stats/SET_SHOW_ALL',
  (showAll: boolean) => {
    return {
      payload: showAll,
    };
  }
);
