import { ContentCard } from './contentCard';
import { useSelected } from '../store/stats/selectors';
import { formatPercents } from '../helpers/formatPercents';
import { GraphGrid } from './graphGrid';
import { Translation } from './translation';
import { Translations } from '../constants/translations';

export const VaccinatedCard = () => {
  const selected = useSelected();

  if (!selected) {
    return (
      <ContentCard
        className="graphCard"
        title={<Translation k={Translations.fully_vaccinated} />}
      >
        <p>
          <Translation k={Translations.select_country} />
        </p>
      </ContentCard>
    );
  }

  const { people_fully_vaccinated_per_hundred } = selected;

  if (!people_fully_vaccinated_per_hundred) {
    return (
      <ContentCard
        className="graphCard"
        title={<Translation k={Translations.fully_vaccinated} />}
      >
        <p>
          <Translation k={Translations.no_vaccination_data} />
        </p>
      </ContentCard>
    );
  }

  return (
    <ContentCard
      className="graphCard"
      title={<Translation k={Translations.fully_vaccinated} />}
      secondaryTitle={formatPercents(people_fully_vaccinated_per_hundred)}
    >
      <GraphGrid percentage={people_fully_vaccinated_per_hundred} />
    </ContentCard>
  );
};
