import { useModal } from '../store/modals/selectors';
import { Modal } from './modal';
import { useDispatch } from 'react-redux';
import { setModals } from '../store/modals/actions';
import { Translation } from './translation';
import { Translations } from '../constants/translations';
import { Block } from './block';
import { Blocks } from '../constants/blocks';
import { Modals } from '../constants/modals';

export const TermsModal = () => {
  const dispatch = useDispatch();
  const active = useModal(Modals.terms);

  return (
    <Modal
      active={active}
      onClose={() => dispatch(setModals(Modals.terms, false))}
      title={<Translation k={Translations.terms_and_conditions} />}
    >
      <Block k={Blocks.terms_and_conditions} />
    </Modal>
  );
};
