export enum Translations {
  ami_and_bami = 'ami_and_bami',
  about = 'about',
  contacts = 'contacts',
  main_title = 'main_title',
  main_subtitle = 'main_subtitle',
  covid_statistics = 'covid_statistics',
  population_by_country = 'population_by_country',
  cases_confirmed = 'cases_confirmed',
  deaths_registered = 'deaths_registered',
  people_vaccinated = 'people_vaccinated',
  percent_vaccinated = 'percent_vaccinated',
  scroll_down = 'scroll_down',
  n_a = 'n_a',
  country = 'country',
  sort_by = 'sort_by',
  a_z = 'a_z',
  z_a = 'z_a',
  choose = 'choose',
  with_data = 'with_data',
  all = 'all',
  fully_vaccinated = 'fully_vaccinated',
  select_country = 'select_country',
  no_vaccination_data = 'no_vaccination_data',
  learn_more = 'learn_more',
  learn_more_link = 'learn_more_link',
  who_link = 'who_link',
  cdc_link = 'cdc_link',
  ecdc_link = 'ecdc_link',
  csse_at_jhu_link = 'csse_at_jhu_link',
  latest_update = 'latest_update',
  amibami_title = 'amibami_title',
  amibami_subtitle = 'amibami_subtitle',
  amibami_card_text = 'amibami_card_text',
  ami = 'ami',
  bami = 'bami',
  terms_and_conditions = 'terms_and_conditions',
  privacy = 'privacy',
  copyright = 'copyright',
  cookies_first = 'cookies_first',
  ok_continue = 'ok_continue',
}
