import { ContentCard } from './contentCard';
import { useSelected } from '../store/stats/selectors';
import { DataList } from './dataList';
import { formatNumber } from '../helpers/formatNumber';
import { formatPercents } from '../helpers/formatPercents';
import { FunctionComponent } from 'react';
import { Translation } from './translation';
import { Translations } from '../constants/translations';

export const InfoCard: FunctionComponent = () => {
  const selected = useSelected();

  if (!selected) {
    return (
      <ContentCard className={`contentCard_data`} noHeader>
        <Translation k={Translations.select_country} />
      </ContentCard>
    );
  }

  const {
    location,
    population,
    total_cases,
    total_deaths,
    people_fully_vaccinated,
    people_fully_vaccinated_per_hundred,
  } = selected;

  return (
    <ContentCard
      title={location}
      className={`contentCard_data`}
      noHeader={!selected}
    >
      <DataList
        title={<Translation k={Translations.covid_statistics} />}
        data={[
          {
            label: <Translation k={Translations.population_by_country} />,
            value: formatNumber(population),
          },
          {
            label: <Translation k={Translations.cases_confirmed} />,
            value: formatNumber(total_cases),
          },
          {
            label: <Translation k={Translations.deaths_registered} />,
            value: formatNumber(total_deaths),
          },
          {
            label: <Translation k={Translations.people_vaccinated} />,
            value: people_fully_vaccinated ? (
              formatNumber(people_fully_vaccinated)
            ) : (
              <Translation k={Translations.n_a} />
            ),
          },
          {
            label: <Translation k={Translations.percent_vaccinated} />,
            value: people_fully_vaccinated_per_hundred ? (
              formatPercents(people_fully_vaccinated_per_hundred)
            ) : (
              <Translation k={Translations.n_a} />
            ),
          },
        ]}
      />
    </ContentCard>
  );
};
