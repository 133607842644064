import { useModal } from '../store/modals/selectors';
import { Modal } from './modal';
import { useDispatch } from 'react-redux';
import { setModals } from '../store/modals/actions';
import { Translation } from './translation';
import { Translations } from '../constants/translations';
import { Block } from './block';
import { Blocks } from '../constants/blocks';
import { Modals } from '../constants/modals';

export const PrivacyModal = () => {
  const dispatch = useDispatch();
  const active = useModal(Modals.privacy);

  return (
    <Modal
      active={active}
      onClose={() => dispatch(setModals(Modals.privacy, false))}
      title={<Translation k={Translations.privacy} />}
    >
      <Block k={Blocks.privacy} />
    </Modal>
  );
};
