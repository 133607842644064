import { ReactNode } from 'react';

export const DataList = ({
  title,
  data = [],
}: {
  title: string | ReactNode;
  data: { label: string | ReactNode; value: string | ReactNode }[];
}) => {
  return (
    <div className="dataList">
      <div className="dataList__title">{title}</div>
      <dl className="dataList__list">
        {data.map((item, index) => {
          return (
            <div className="dataList__listItem" key={index}>
              <dt>{item.label}:</dt>
              <dd>{item.value}</dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
};
